// extracted by mini-css-extract-plugin
export var card = "styled-card-module--card--a4oQT";
export var circle = "styled-card-module--circle--RMfoa";
export var circled = "styled-card-module--circled--XKiuS";
export var clickable = "styled-card-module--clickable--2gmo7";
export var content = "styled-card-module--content--ZlPCs";
export var link = "styled-card-module--link--qusdg";
export var linked = "styled-card-module--linked--UfPRb";
export var noPhone = "styled-card-module--noPhone--YvU6D";
export var notHoverable = "styled-card-module--notHoverable--ubRue";
export var reverse = "styled-card-module--reverse--vFfU3";
export var smallRadius = "styled-card-module--smallRadius--2SNdh";
export var stable = "styled-card-module--stable--Nod55";