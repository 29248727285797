import React from "react"
import classNames from "classnames"

// Styles
import * as styles from "./styled-card.module.sass"
import PropTypes from "prop-types"

const StyledCard = ({ children, theme, link, external }) => {
  if (link) {
    if (external) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={classNames(styles.card, styles.linked, {
            [styles.reverse]: theme && theme.includes("reverse"),
            [styles.notHoverable]: theme && theme.includes("notHoverable"),
            [styles.stable]: theme && theme.includes("stable"),
            [styles.circled]: theme && theme.includes("circle"),
          })}
        >
          
          <div className={styles.content}>{children}</div>
        </a>
      )
    } else {
      return (
        <a
          href={link}
          className={classNames(styles.card, styles.linked, {
            [styles.reverse]: theme && theme.includes("reverse"),
            [styles.notHoverable]: theme && theme.includes("notHoverable"),
            [styles.stable]: theme && theme.includes("stable"),
            [styles.circled]: theme && theme.includes("circle"),
          })}
        >
          
          <div className={styles.content}>{children}</div>
        </a>
      )
    }
  }
  return (
    <div
      className={classNames(styles.card, {
        [styles.reverse]: theme && theme.includes("reverse"),
        [styles.notHoverable]: theme && theme.includes("notHoverable"),
        [styles.stable]: theme && theme.includes("stable"),
        [styles.circled]: theme && theme.includes("circle"),
      })}
    >
      <div className={styles.content}>{children}</div>
    </div>
  )
}

StyledCard.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string,
  external: PropTypes.bool,
}

export default StyledCard
