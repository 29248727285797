import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Styles
import * as styles from "./section.module.sass"

const Section = ({ children, className }) => (
  <section className={classNames(styles.section, className)}>
    {children}
  </section>
)

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Section
