import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Styles
import * as styles from "./heading.module.sass"

const Heading = ({ children, theme }) => (
  <div
    className={classNames(styles.heading, {
      [styles.left]: theme && theme === "left",
    })}
  >
    {children}
  </div>
)

Heading.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Heading
