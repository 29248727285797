// extracted by mini-css-extract-plugin
export var active = "become-partner-module--active--2SWsq";
export var arrow = "become-partner-module--arrow--8swsN";
export var bar = "become-partner-module--bar--8ZOSL";
export var barInner = "become-partner-module--barInner--CQM8W";
export var barUnder = "become-partner-module--barUnder--t9VoZ";
export var block = "become-partner-module--block--NLI8l";
export var button = "become-partner-module--button--fbGgR";
export var buttons = "become-partner-module--buttons--a8vS8";
export var circle = "become-partner-module--circle--oBLb3";
export var circleInner = "become-partner-module--circleInner--VExCk";
export var container = "become-partner-module--container--eLYwM";
export var icon = "become-partner-module--icon--yLdrL";
export var iconCircle = "become-partner-module--iconCircle--q+jrB";
export var iconInner = "become-partner-module--iconInner--vKsjV";
export var inner = "become-partner-module--inner--Vak20";
export var last = "become-partner-module--last--KuzOI";
export var progress = "become-partner-module--progress--1nMdC";
export var steps = "become-partner-module--steps--Wn6yJ";
export var titleWrapper = "become-partner-module--titleWrapper--8yJsK";