import React from "react"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import StyledCard from "../../../ui-kit/StyledCard/StyledCard"

// Styles
import * as styles from "./Offers.module.sass"
import Section from "../../../ui-kit/Section/Section"
import LazyHydrate from "react-lazy-hydration"

const Offer = ({ data, header }) => {
  return (
    <Section className={styles.section}>
      <Container>
        <Row>
          <div className={styles.hcontainer}>
            <LazyHydrate ssrOnly>
              <h2>{header}</h2>
            </LazyHydrate>
          </div>
          <div className={styles.cards}>
            {data.map(offer => (
              <div key={offer.id} className={styles.card}>
                <StyledCard>
                  <div className={styles.inner}>
                    <div className={styles.icon}>
                      <StyledCard theme={"circle"}>
                        <div className={styles.iconContent}>
                          <div className={styles.iconContentImage}>
                            <img src={offer.icon} alt={offer.title} />
                          </div>
                        </div>
                      </StyledCard>
                    </div>
                    <LazyHydrate ssrOnly>
                      <h3>{offer.title}</h3>
                    </LazyHydrate>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </Row>
      </Container>
    </Section>
  )
}

export default Offer
