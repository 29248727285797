import React, { useEffect, useState } from "react"
import classNames from "classnames"

// Components
import Container from "../../../ui-kit/Container/Container"
import Row from "../../../ui-kit/Row/Row"
import Heading from "../../../ui-kit/Heading/Heading"
import ArrowButton from "../../../ui-kit/ArrowButton/ArrowButton"
import Button from "../../../ui-kit/Button/Button"
import Section from "../../../ui-kit/Section/Section"

// Styles
import * as styles from "./become-partner.module.sass"
import LazyHydrate from "react-lazy-hydration"
import { useCurrentTheme } from "../../../utils/themeChanger"

const BecomePartner = ({ data, header, step, stepAdditional, button }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const [disabled, setDisabled] = useState(false)
  const [active, setActive] = useState({
    firstStage: false,
    opacityAll: false,
  })

  const animation = func => {
    setDisabled(true)
    setActive(active => ({
      ...active,
      firstStage: true,
    }))
    setTimeout(() => {
      setActive(active => ({
        ...active,
        opacityAll: true,
      }))
      setTimeout(() => {
        func()
        setTimeout(() => {
          setActive(active => ({
            ...active,
            opacityAll: false,
          }))
          setTimeout(() => {
            setActive(active => ({
              ...active,
              firstStage: false,
            }))
            setDisabled(false)
          }, 400)
        }, 100)
      }, 700)
    }, 400)
  }

  const [width, setWidth] = useState(0)
  const [state, setState] = useState({
    point: 1,
    info: data[0],
  })

  const nextSlide = () => {
    if (!disabled) {
      animation(() => {
        setState({
          point: state.point + 1,
          info: data[state.point],
        })
        setWidth(distanceFromLeft(`${state.point + 1}`))
      })
    }
  }

  const previousSlide = () => {
    if (!disabled) {
      animation(() => {
        setState({
          point: state.point - 1,
          info: data[state.point - 2],
        })
        setWidth(distanceFromLeft(`${state.point - 1}`))
      })
    }
  }

  const amountSlide = index => {
    if (!disabled) {
      animation(() => {
        setState({
          point: index + 1,
          info: data[index],
        })
        setWidth(distanceFromLeft(`${index + 1}`))
      })
    }
  }

  const distanceFromLeft = distance => {
    return parseInt(
      document
        .querySelector(`.${styles.circle}:nth-of-type(${distance})`)
        ?.getBoundingClientRect().x -
        document.querySelector(`.${styles.bar}`)?.getBoundingClientRect().x,
      10
    )
  }

  useEffect(() => {
    setWidth(distanceFromLeft(`${state.point}`))
  }, [])

  return (
    <Section>
      <Container>
        <Row>
          <LazyHydrate ssrOnly>
            <Heading>
              <h2>{header}</h2>
            </Heading>
          </LazyHydrate>
          <LazyHydrate whenIdle>
            <div className={styles.block}>
              <p className={styles.steps}>
                {step} {state.point}/<span>{data.length}</span> {stepAdditional}
              </p>
              <div className={styles.container}>
                <div className={styles.arrow}>
                  <ArrowButton
                    theme="notHoverable"
                    disabled={state.point === 1 || disabled}
                    onClick={() => previousSlide()}
                  />
                </div>
                <div
                  className={classNames(styles.inner, {
                    [styles.active]: active.opacityAll,
                  })}
                >
                  <div
                    className={classNames(styles.icon, {
                      [styles.active]: active.firstStage,
                    })}
                  >
                    <div className={styles.iconCircle}>
                      <div className={styles.iconInner}>
                        <img
                          src={
                            isLightTheme
                              ? state.info.icon.localFile.publicURL
                              : state.info.icon_dark.localFile.publicURL
                          }
                          alt={state.info.title}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.titleWrapper}>
                    {!active.opacityAll && <h3>{state.info.title}</h3>}
                  </div>

                  {state.info.with_button === "true" && (
                    <div className={styles.button}>
                      <Button
                        link="https://marketplace.zionodes.com/signup/"
                        external
                      >
                        {button}
                      </Button>
                    </div>
                  )}
                </div>
                <div className={styles.arrow}>
                  <ArrowButton
                    theme="notHoverable"
                    type="right"
                    disabled={state.point === data.length || disabled}
                    onClick={() => nextSlide()}
                  />
                </div>
              </div>
              <div className={styles.progress}>
                <div className={styles.buttons}>
                  {data.map((el, index) => (
                    <button
                      key={el.id}
                      type="button"
                      className={classNames(styles.circle, {
                        [styles.active]: index < state.point,
                      })}
                      onClick={() => amountSlide(index)}
                    >
                      <span className={styles.circleInner} />
                    </button>
                  ))}
                </div>
                <div className={styles.bar}>
                  <div className={styles.barUnder}>
                    <div className={styles.barInner} style={{ width: width }} />
                  </div>
                </div>
              </div>
            </div>
          </LazyHydrate>
        </Row>
      </Container>
    </Section>
  )
}

export default BecomePartner
