import React from "react"
import classNames from "classnames"

// Icons
import ArrowLeft from "../../icons/light/arrows/arrow-left.svg"
import ArrowLeftDisabled from "../../icons/light/arrows/arrow-left-disabled.svg"
import ArrowRight from "../../icons/light/arrows/arrow-right.svg"
import ArrowRightDisabled from "../../icons/light/arrows/arrow-right-disabled.svg"

import ArrowLeftDark from "../../icons/dark/arrows/arrow-left.svg"
import ArrowLeftDisabledDark from "../../icons/dark/arrows/arrow-left-disabled.svg"
import ArrowRightDark from "../../icons/dark/arrows/arrow-right.svg"
import ArrowRightDisabledDark from "../../icons/dark/arrows/arrow-right-disabled.svg"

import { useCurrentTheme } from "../../utils/themeChanger"

// Styles
import * as styles from "./arrow-button.module.sass"

const ArrowButton = ({ onClick, theme, disabled, type = "left", content }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  let inner
  if (type === "left") {
    inner = (
      <>
        <div className={styles.icon}>
          <div className={styles.disabled}>
            <img
              src={isLightTheme ? ArrowLeftDisabled : ArrowLeftDisabledDark}
              alt="Arrow left(disabled)"
            />
          </div>
          <div className={styles.enabled}>
            <img
              src={isLightTheme ? ArrowLeft : ArrowLeftDark}
              alt="Arrow left"
            />
          </div>
        </div>
        <span>
          <span>{content}</span>
        </span>
      </>
    )
  } else {
    inner = (
      <>
        <span>
          <span>{content}</span>
        </span>
        <div className={styles.icon}>
          <div className={styles.disabled}>
            <img
              src={isLightTheme ? ArrowRightDisabled : ArrowRightDisabledDark}
              alt="Arrow right(disabled)"
            />
          </div>
          <div className={styles.enabled}>
            <img
              src={isLightTheme ? ArrowRight : ArrowRightDark}
              alt="Arrow left"
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <button
      disabled={disabled}
      className={classNames(styles.button, {
        [styles.revert]: type === "left",
        [styles.notHoverable]: theme === "notHoverable",
      })}
      onClick={() => onClick()}
    >
      <div className={classNames(styles.border)} />
      <div className={classNames(styles.background)}>
        <div className={classNames(styles.inner)}>{inner}</div>
      </div>
    </button>
  )
}

export default ArrowButton
