import React from "react"
import YAMLGlobal from "../content/global.yaml"
import YAMLContent from "../content/partnersPage.yaml"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import BecomePartner from "../components/PartnersPage/BecomePartner/BecomePartner"
import Apply from "../components/PartnersPage/ApplyToPartner/ApplyToPartner"
import Offer from "../components/PartnersPage/Offers/Offers"
import { graphql, useStaticQuery } from "gatsby"

import { useCurrentTheme } from "../utils/themeChanger"

const PartnersPage = ({ location }) => {
  const colorTheme = useCurrentTheme()
  const isLightTheme = colorTheme.data?.theme === "light"

  const data = useStaticQuery(graphql`
    query {
      partners: directusAboutEng {
        seo_title
        seo_description
        seo_keywords
        seo_image {
          localFile {
            childImageSharp {
              fixed(quality: 90, fit: COVER, height: 630, width: 1200) {
                src
              }
            }
          }
        }
        first_block_titlee
        first_block_descr
        first_block_link
        second_block_title
        second_block_description
        second_block_link
        what_do_we_offer_first_icon {
          localFile {
            publicURL
          }
        }
        what_do_we_offer_first_icon_dark {
          localFile {
            publicURL
          }
        }
        what_do_we_offer_first_title
        what_do_we_offer_second_icon {
          localFile {
            publicURL
          }
        }
        what_do_we_offer_second_icon_dark {
          localFile {
            publicURL
          }
        }
        what_do_we_offer_second_title
        become_certs {
          id
          title
          with_button
          icon {
            localFile {
              publicURL
            }
          }
          icon_dark {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const seo = {
    title: data.partners.seo_title,
    description: data.partners.seo_description,
    keywords: data.partners.seo_keywords,
    image: data.partners.seo_image.localFile.childImageSharp.fixed.src,
  }

  const firstScreen = {
    firstBlock: {
      title: data.partners.first_block_titlee,
      description: data.partners.first_block_descr,
      link: data.partners.first_block_link,
    },
    secondBlock: {
      title: data.partners.second_block_title,
      description: data.partners.second_block_description,
      link: data.partners.second_block_link,
    },
  }

  const whatDoWeOffer = [
    {
      id: 0,
      icon: isLightTheme
        ? data.partners.what_do_we_offer_first_icon.localFile.publicURL
        : data.partners.what_do_we_offer_first_icon_dark.localFile.publicURL,
      title: data.partners.what_do_we_offer_first_title,
    },
    {
      id: 1,
      icon: isLightTheme
        ? data.partners.what_do_we_offer_second_icon.localFile.publicURL
        : data.partners.what_do_we_offer_second_icon_dark.localFile.publicURL,
      title: data.partners.what_do_we_offer_second_title,
    },
  ]

  const becomeCertsPartner = data.partners.become_certs

  return (
    <Layout location={location}>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        image={seo.image}
      />

      <Apply
        data={firstScreen}
        header={YAMLContent.apply.header}
        button={YAMLGlobal.apply}
      />
      <Offer data={whatDoWeOffer} header={YAMLContent.whatDoWeOffer.header} />

      <BecomePartner
        data={becomeCertsPartner}
        header={YAMLContent.becomePartner.header}
        step={YAMLContent.becomePartner.step}
        stepAdditional={YAMLContent.becomePartner.stepAdditional}
        button={YAMLGlobal.onboard}
      />
    </Layout>
  )
}

export default PartnersPage
